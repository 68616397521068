import { HasDateCreatedTimestamp } from '@ts/shared/date-time/util-core';
import { User } from '@ts/user/shared/util-core';

export type OrderStatus =
  | 'Being processed'
  | 'Cancelled'
  | 'Complete'
  | 'Confirm await'
  | 'Confirm pay'
  | 'Edited'
  | 'In delivery'
  | 'Paid'
  | 'Payment expired'
  | 'Pending'
  | 'Processed';

export const ORDER_STATUSES: OrderStatus[] = [
  'Being processed',
  'Cancelled',
  'Complete',
  'Confirm await',
  'Confirm pay',
  'Edited',
  'In delivery',
  'Paid',
  'Payment expired',
  'Pending',
  'Processed',
];

interface OrderStatusEventBase extends HasDateCreatedTimestamp {
  status_before: OrderStatus;
  status_after: OrderStatus;
  notes: string;
  user: User;
}

export type OrderStatusEvent = Readonly<OrderStatusEventBase>;

export interface OrderStatusChangeParams {
  status_from: OrderStatus;

  // new status
  status: OrderStatus;

  note: string;
}
