import { Environment } from './environment.model';

export const environment: Environment = {
  production: true,
  logLevel: 'info',

  app: 'solui',

  bormaDagoApiUrl: 'https://api.bormadago.com',

  soluiUrl: 'https://www.bormadago.com',

  sentryDsn:
    'https://97fcda0c8a0247cbb2767173dd1eb107@o511135.ingest.sentry.io/6115957',
  sentryTracesSampleRate: 0.01,

  googleMapsApiKey: 'AIzaSyDZnVvq3ktL4d_Nhx-1EMzcRyUM7s8T-sU',

  googlePlayUrl:
    'https://play.google.com/store/apps/details?id=com.bormadago.app',

  appStoreUrl: '',

  recaptchaSiteKey: '6Lf6jvkiAAAAAOu8Zv_mCamNo8kKlftGNU55dCTg',

  firebaseConfig: {
    apiKey: 'AIzaSyACW71YHl78AK6XFf07ADHY_e3q3GdWVMQ',
    appId: '1:678535079577:web:d5dd3307bbe1459c8bba08',
    projectId: 'solui-production',
    measurementId: 'G-PKX6WQYPGQ',
  },

  midtransClientKey: 'Mid-client-MScQkyooD0T5LBFL',

  midtransSnapUrl: 'https://app.midtrans.com/snap/snap.js',

  dokuJokulUrl:
    'https://jokul.doku.com/jokul-checkout-js/v1/jokul-checkout-1.0.0.js',
};
